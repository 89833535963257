import * as Sentry from '@sentry/react'
import { browserTracingIntegration } from '@sentry/react'
import posthog from 'posthog-js'

export const initSentry = () => {
  if (!import.meta.env.PROD) {
    return
  }
  // TODO: init only if cookies have been accepted via Cookie Banner
  Sentry.init({
    dsn: 'https://502879bca289458c9acbf88d6f1db4de@o342149.ingest.sentry.io/6543790',
    environment: import.meta.env.VITE_REACT_APP_ENV,
    release: import.meta.env.VITE_REACT_APP_SENTRY_RELEASE_VERSION,
    integrations: [
      browserTracingIntegration(),
      posthog.sentryIntegration({
        organization: 'pactum',
        projectId: 6543790,
      }),
    ],
    tracesSampleRate: 0.25,
  })
}
