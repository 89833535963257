import posthog from 'posthog-js'
import { getCookieConsentValue } from 'react-cookie-consent'
import { shouldShowCookieConsent } from '@pactum/frontend-utils'

declare let window: Window & { _DATADOG_SYNTHETICS_BROWSER?: unknown }

export const initPosthog = () => {
  posthog.init('phc_zKPFXER6V7MFdE0PcftSdC8EYfiLyevlZyhEAUql4P', {
    api_host: 'https://eu.posthog.com',
    session_recording: {
      maskAllInputs: false,
      maskInputOptions: { password: true },
    },
    persistence: cookieConsentYes() ? 'localStorage+cookie' : 'memory',
    loaded: function (hog) {
      if (!shouldCapture()) {
        hog.opt_out_capturing()
      }
    },
  })
}

const shouldCapture = () => {
  if (import.meta.env.VITE_REACT_APP_ENV !== 'production') {
    return false
  }

  if (!cookieConsentYes()) {
    return false
  }

  if (window?._DATADOG_SYNTHETICS_BROWSER !== undefined) {
    return false
  }

  return true
}

const cookieConsentYes = () => {
  return !(shouldShowCookieConsent() && getCookieConsentValue() !== 'true')
}

export enum EventType {
  INIT_START = 'suite.init.start',
  INIT_ORGS = 'suite.init.orgs',
  INIT_AUTHORIZED = 'suite.init.authorized',
  INIT_BRANDINGS = 'suite.init.branding',
  INIT_USER = 'suite.init.user',
}

let previousEventTime: number | null = null
let previousEventType: EventType | null = null

export const captureEvent = (type: EventType) => {
  posthog.capture(type, {
    timeSincePreviousEvent: previousEventTime ? Date.now() - previousEventTime : 0,
    previousEventType,
  })

  previousEventType = type
  previousEventTime = Date.now()
}
